import React from 'react'
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";


export default function IconsRRSS() {
	const openNewTab = (url) => {
		window.open(
			url,
		);
	}
	return (
		<div className='container-rrss'>
			<div>
				<FaInstagram onClick={() => openNewTab("https://www.instagram.com/brokerworldcargo/?igsh=YzVkODRmOTdmMw%3D%3D")} size={"3em"} />
			</div>
			<div>
				<FaLinkedin onClick={() => openNewTab("https://www.linkedin.com/in/broker-world-cargo-spa-7705b2196/")} size={"3em"} />
			</div>
		</div>
	)
}
