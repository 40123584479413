import React from "react";
import aImg from "../../images/about/img-1.jpg";

const About = (props) => {
  return (
    <section className="tf-about-section section-padding">
      <div className="container">
        <div className="tf-about-wrap">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="tf-about-img">
                <GifRenderer src="/about-us.gif"/>
                <div className="tf-about-img-text">
                  <div className="tf-about-icon">
                    <h3>12</h3>
                    <span>Años</span>
                    <span>de experiencia</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="tf-about-text">
                <small>about Me</small>
                <h2>Excelencia en importaciones</h2>
                <h5>
                  Contamos con más de 12 años de experiencia en importaciones marítimas, aéreas y terrestres.
                </h5>
                <p>
                  Hace 12 años, se materializó un sueño divino: Broker. Desde entonces, nos hemos dedicado a ofrecer un servicio de excelencia, ganándonos la confianza y lealtad de nuestros clientes gracias al compromiso y liderazgo de nuestro equipo. Juntos hemos crecido, aprendiendo de la diversidad de sectores de nuestros clientes, lo cual nos ha beneficiado al negociar costos internacionalmente. Un agradecimiento especial a nuestros proveedores, cuya calidad y esfuerzo hacen posible nuestro servicio de primera.
                </p>

                {/* <div className="tf-funfact">
                  <div className="tf-funfact-item">
                    <h3>
                      <span>500</span>+
                    </h3>
                    <p>Proyectos realizados</p>
                  </div>
                  <div className="tf-funfact-item d-none">
                    <h3>
                      <span>52</span>+
                    </h3>
                    <p>Awards Win</p>
                  </div>
                  <div className="tf-funfact-item">
                    <h3>
                      <span>2</span>M+
                    </h3>
                    <p>Clientes felices</p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="visible-rotate-text">
        <h1>About Me</h1>
      </div>
    </section>
  );
};

export default About;

const GifRenderer = ({ src, width = '100%' }) => {
    return (
        <img 
            src={src} 
            // style={{ width: "540px", height: '636px' }} 
            alt="GIF" 
        />
    );
};
