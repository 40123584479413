import React, { useState } from "react";
import Services from "../../api/service";
import ServiceSingle from "../ServiceSingle";

const ServiceSection = () => {
  const [open, setOpen] = React.useState(false);

  function handleClose() {
    setOpen(false);
  }

  const [state, setState] = useState({});

  const handleClickOpen = (item) => {
    // return null
    setOpen(true);
    setState(item);
  };
  console.log("state", state);
  return (
    <div className="tp-service-area section-padding">
      <div className="container">
        <div className="tp-section-title">
          <span>Check services</span>
          <h2>Nuestros servicios</h2>
        </div>
        <div className="tp-service-wrap">
          <div className="row align-items-center">
            {Services.slice(0, 4).map((service, srv) => {
              const Icon = service.Icon2

              return (
                <div onClick={() => handleClickOpen(service)} className="col col-lg-3 col-md-6 col-12" key={srv}>
                  <div className="tp-service-item" style={{ height: "25em" }}>
                    {/* <i className={`fi ${service.icon}`}></i> */}
                    {/* <img src={service.icon2} width={"50px"} alt="" className="svg_service" /> */}
                    <Icon className="svg_service" width={50} height={50}/>
                    <h2>
                      {service.sTitle}
                    </h2>
                    <p>
                      {service.description}
                    </p>
                    {/* <button
                    className="read-more"
                    onClick={() => handleClickOpen(service)}
                  >
                    <i className="fi flaticon-right-arrow"></i>
                  </button> */}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="visible-rotate-text">
        <h1>Services</h1>
      </div>
      <ServiceSingle
        open={open}
        descriptionLarge={state.descriptionLarge}
        d={state.d}
        onClose={handleClose}
        title={state.sTitle}
        dImg={state.sImgS}
        sImg1={state.ssImg1}
        sImg2={state.ssImg2}
      />
    </div>
  );
};

export default ServiceSection;
