import consults from '../images/POP-UP-T-asesoría.jpg'
import imgAir from '../images/POP-UP-T-aereo.jpg'
import imgTerrestre from '../images/POP-UP-T-terrestre.jpg'
import imgOcean from '../images/POP-UP-T-mar.jpg'

import avionAzul from '../images/iconos/Avion-azul_1.js'
import barcoAzul from '../images/iconos/barco-azul_1.js'
import camionAzul from '../images/iconos/camion-azul_1.js'
import consultoraAzul from '../images/iconos/consultora-azul_1.js'


// import sgIXmgS1 from '../images/service-single/web/img-1.jpg'
import sgImgS2 from '../images/service-single/web/img-2.jpg'
import sgImgS3 from '../images/service-single/web/img-3.jpg'

// import brImgS1 from '../images/service-single/app/img-1.jpg'
// import brImgS2 from '../images/service-single/app/img-2.jpg'
// import brImgS3 from '../images/service-single/app/img-3.jpg'

import uxImgS1 from '../images/service-single/brand/img-1.jpg'
// import uxImgS2 from '../images/service-single/brand/img-2.jpg'
// import uxImgS3 from '../images/service-single/brand/img-3.jpg'

// import dvImgS1 from '../images/service-single/market/img-1.jpg'
// import dvImgS2 from '../images/service-single/market/img-2.jpg'
// import dvImgS3 from '../images/service-single/market/img-3.jpg'


const Services = [
    {
        Id: '1',
        Icon2: consultoraAzul,
        sImgS: consults,
        sTitle: 'Consultoría - Asesoría',
        description: 'Asistencia en todas las etapas para su operación de comercio internacional y soluciones creativas para aplicar eficientemente los recursos de su empresa.',
        des2: 'Incluye servicios como Cotizaciones de Costos, Personal fluente en Inglés, Gerenciamiento del Proyecto Logístico, entre otros.',
        des3: 'Especialización en legislación aduanera y manejo de documentación con las mejores tarifas de fletes y booking.',
        icon: 'flaticon-vector',
        projects: 'Número de proyectos o clientes', // Añade el número correspondiente
        ssImg1: sgImgS2,
        ssImg2: sgImgS3,
        "descriptionLarge": `Descubra la excelencia en servicios de importación global con nuestro equipo especializado. Desde evaluaciones detalladas de costos hasta la gestión logística eficiente de proyectos, ofrecemos soluciones integrales para su operación de comercio internacional.`,
        d: [
            {
                title: "En la Exportación",
                list: ["Evaluación de costos.", "Personal con habilidades en inglés.", "Gestión logística del proyecto.", "Análisis y ajuste de estándares de embalaje.", "Conocimiento de legislación aduanera nacional e internacional.", "Manejo de documentación.", "Obtención de las mejores tarifas de fletes y reservas.", "Despacho aduanero en los principales puertos, aeropuertos y fronteras nacionales e internacionales."]
            },
            {
                title: "En la Importación",
                list: ["Contacto con el proveedor.",
                    "Análisis y gerenciamiento de todas las etapas previas al embarque.",
                    "Clasificación de la carga.",
                    "Licencia de Importación.",
                    "Registro de operación financiera.",
                    "Agentes freight forwarders en los principales países del mundo.",
                    "Proyecto logístico con experiencia en carga oversized y heavylift.",
                    "Operaciones cambiarias (Money exchange).",
                    "Despachos aduaneros en los principales puerto.s, aeropuertos y fronteras nacionales e internacionales.",]
            },
        ]
    },
    {
        Id: '2',
        Icon2: avionAzul,
        sImgS: imgAir,
        sTitle: 'Transporte Aéreo',
        description: 'Servicios integrales para importaciones y exportaciones, incluyendo el manejo de cargas y documentación en aeropuerto.',
        des2: 'Ofrecemos Transporte de Cargas Generales, Restringidas y Vuelos Chárter.',
        des3: 'Experiencia en manejo eficiente y seguro de cargas aéreas.',
        icon: 'flaticon-smartphone',
        projects: 'Número de proyectos o clientes',
        ssImg1: sgImgS2,
        ssImg2: sgImgS3,
        "descriptionLarge": "Experimente una logística aérea sin igual con nuestros servicios especializados en importaciones y exportaciones. Gestionamos eficientemente cargas y documentación en aeropuertos, ofreciendo transporte tanto para cargas generales como restringidas. Además, facilitamos vuelos chárter para adaptarnos a sus necesidades específicas.",

        d: [
            {
                title: "TRANSPORTE AÉREO",
                list: ["Importaciones y Exportaciones.",
                    "Manejo de cargas y documentación en aeropuerto.",
                    "Transporte de Cargas Generales.",
                    "Transporte de Cargas Restringidas.",
                    "Vuelos Chárter.",]
            },
        ]
    },
    {
        Id: '3',
        Icon2: camionAzul,
        sImgS: imgTerrestre,
        sTitle: 'Transporte Terrestre',
        description: 'Ofrecemos fletes nacionales e internacionales con una completa gestión de la carga y documentación.',
        des2: 'Servicio de entrega puerta a puerta con cargas normales, peligrosas, consolidadas y sobredimensionadas.',
        des3: 'Disponemos de una flota de camiones propia para garantizar el servicio.',
        icon: 'flaticon-palette',
        projects: 'Número de proyectos o clientes',
        ssImg1: sgImgS2,
        ssImg2: sgImgS3,
        "descriptionLarge": "Optimice su cadena de suministro con nuestro servicio de transporte terrestre. Realizamos fletes nacionales e internacionales, garantizando entregas puerta a puerta. Manejamos todo tipo de carga, desde normales hasta peligrosas, consolidadas y sobredimensionadas. Con nuestra flota de camiones propia, aseguramos un transporte confiable y eficiente.",

        d: [
            {
                title: "TRANSPORTE TERRESTRE",
                list: ["Fletes Nacionales e Internacionales.",
                    "Entrega Puerta a Puerta.",
                    "Manejo de Carga y Documentación.",
                    "Cargas normales, peligrosas, consolidadas y sobredimensionadas.",
                    "Flota de camiones propia.",]
            },
        ]
    },
    {
        Id: '4',
        Icon2: barcoAzul,
        sImgS: imgOcean,
        sTitle: 'Transporte Marítimo',
        description: 'Servicios de importación y exportación marítima con manejo de cargas y documentación en puerto.',
        des2: 'Ofrecemos servicios de Full Containers (FLC) y Consolidados (LCL) además de cargas restringidas.',
        des3: 'Experiencia en servicios Chárter y operaciones logísticas avanzadas.',
        icon: 'flaticon-bar-chart',
        projects: 'Número de proyectos o clientes',
        ssImg1: sgImgS2,
        ssImg2: sgImgS3,
        "descriptionLarge": "Sumérjase en el mundo del comercio internacional con nuestro servicio de transporte marítimo. Facilitamos importaciones y exportaciones, manejando hábilmente cargas y documentación en puertos. Ofrecemos opciones de Full Containers (FLC) y Consolidados (LCL), así como servicios chárter para adaptarnos a sus necesidades específicas, incluso para cargas restringidas. Con nosotros, su logística marítima está en buenas manos.",

        d: [
            {
                title: "TRANSPORTE MARÍTIMO",
                list: ["Importaciones y exportaciones.",
                    "Manejo de cargas y documentación en puerto.",
                    "Full Containers (FLC) y Consolidados (LCL).",
                    "Cargas restringidas.",
                    "Servicios Chárter.",]
            },
        ]
    }
];


export default Services;