import React from 'react';

// Componente funcional VerticalVideo
const VerticalVideo = ({ videoId = "lo88HZ1iuc0" }) => {
	// Define las proporciones para el video vertical
	const style = {
		height: '600px',   // Ajusta según la proporción vertical deseada
		width: '337px',    // Ajusta según la proporción vertical deseada
	};

	return (
		<div>
			<iframe
				style={style}
				src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
				title="YouTube video player"
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen>
			</iframe>
		</div>
	);
}

export default VerticalVideo;
