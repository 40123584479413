import React, { useState } from "react";
import SimpleReactValidator from "simple-react-validator";

const ContactForm = () => {
  const [forms, setForms] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });
  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );
  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();
      setForms({
        name: "",
        email: "",
        subject: "",
        phone: "",
        message: "",
      });
    } else {
      validator.showMessages();
    }
  };

  return (
    <div className="col-12" style={{ display: "flex", justifyContent: "center" }}>
      <button onClick={() => window.open("https://wa.me/56962093732")} type="submit" className="theme-btn" style={{ fontSize: "2em" }}>
        Contáctanos
      </button>
    </div>
  );
};

export default ContactForm;
