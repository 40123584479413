import React from "react";
import { NavLink } from 'react-router-dom'
import Video from "../video"
import IconsRRSS from "./IconsRRSS"
const Hero = () => {
    return (
        <section className="tp-hero-section-1">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-6 col-lg-6">
                        <div className="tp-hero-section-text">
                            <div className="tp-hero-title">
                                <h2>Con nosotros, tu carga siempre llega segura.</h2>
                            </div>
                            <div className="tp-hero-sub">
                                <p>Broker World Cargo</p>
                            </div>
                            <div className="btns">
                                <button onClick={() => window.open("https://wa.me/56962093732")} className="theme-btn">Contáctanos</button>
                                <IconsRRSS />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="right-vec">
                <div className="right-img">
                    <Video />
                </div>
            </div>
            <div className="social-link">
                <ul>
                    <li><NavLink to="/">Facebook</NavLink></li>
                    <li><NavLink to="/">Twitter</NavLink></li>
                    <li><NavLink to="/">Instagram</NavLink></li>
                </ul>
            </div>
            <div className="visible-text">
                <h1>Broker World Cargo</h1>
            </div>
        </section >
    )
}

export default Hero;