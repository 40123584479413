import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";





const Testimonials = [
    {
        name: 'Elizabeth Maravilla',
        title: 'Gerente de Logística',
        descriptoion: '“¡Excelente servicio! La importación fue rápida y sin contratiempos. Definitivamente confiaré en ellos para futuras operaciones.”',
    },
    {
        name: 'Mario J. Fernández',
        title: 'CEO de Bravo Internacional',
        descriptoion: 'Gracias por hacer que la logística sea lo más fácil posible. Su atención al detalle y el servicio al cliente son incomparables.”',
    },
    {
        name: 'Elena Márquez',
        title: 'Directora de Operaciones Marítimas',
        descriptoion: '“¡Impactados con la eficiencia! Cada envío llega a tiempo y en perfecto estado. Han transformado nuestra cadena de suministro.”',
    },

]



const Testimonial = () => {

    var settings = {
        dots: false,
        arrows: false,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (

        <section className="tp-testimonial-section section-padding">
            <div className="container">
                <div className="tp-section-title">
                    <span>Testimonials</span>
                    <h2>Que dicen nuestros clientes.</h2>
                </div>

                <div className="tp-testimonial-wrap">
                    <Slider {...settings}>
                        {Testimonials.map((tstml, tsm) => (
                            <div className="tp-testimonial-item" key={tsm}>
                                <div className="tp-testimonial-text">
                                    <p>{tstml.descriptoion}</p>
                                    <span>{tstml.name}</span>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
            <div className="visible-rotate-text">
                <h1>Review</h1>
            </div>
        </section>
    )
}

export default Testimonial;